import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

import { CLUB_STATUSES } from "@/constants";

import ClubPageHeroContent from "../ClubPageHeroContent";
import styles from "./ClubPageHero.module.scss";

const ClubPageHero = ({ club, data, facility, moduleList }) => {
  const heroHeightRef = useRef(0);
  const heroRef = useRef(null);
  const isResizeTickingRef = useRef(false);
  const isScrollTickingRef = useRef(false);
  const heroMarginBlockEndRef = useRef(0);
  const windowScrollYRef = useRef(0);
  const windowWidthRef = useRef(0);

  const resizeListener = () => {
    windowWidthRef.current = window.innerHeight;

    if (!isResizeTickingRef.current) {
      window.requestAnimationFrame(() => {
        if (heroRef.current) {
          heroHeightRef.current = heroRef.current.offsetHeight;

          if (
            0 <= windowScrollYRef.current &&
            windowScrollYRef.current <= heroHeightRef.current
          ) {
            heroRef.current.style.marginBlockEnd =
              (heroHeightRef.current - windowScrollYRef.current) * -1 + "px";
          }
        }

        isResizeTickingRef.current = false;
      });

      isResizeTickingRef.current = true;
    }
  };

  const scrollListener = () => {
    windowScrollYRef.current = window.scrollY;

    if (!isScrollTickingRef.current) {
      window.requestAnimationFrame(() => {
        if (heroRef.current) {
          let nextHeroMarginBlockEnd = heroMarginBlockEndRef.current;

          if (
            0 < windowScrollYRef.current &&
            windowScrollYRef.current < heroHeightRef.current
          ) {
            nextHeroMarginBlockEnd =
              (heroHeightRef.current - windowScrollYRef.current) * -1;
          } else if (
            windowScrollYRef.current === 0 &&
            nextHeroMarginBlockEnd !== heroHeightRef.current * -1
          ) {
            nextHeroMarginBlockEnd = heroHeightRef.current * -1;
          } else if (
            windowScrollYRef.current >= heroHeightRef.current &&
            nextHeroMarginBlockEnd !== 0
          ) {
            nextHeroMarginBlockEnd = 0;
          }

          if (heroMarginBlockEndRef.current !== nextHeroMarginBlockEnd) {
            heroRef.current.style.marginBlockEnd =
              nextHeroMarginBlockEnd + "px";
            heroMarginBlockEndRef.current = nextHeroMarginBlockEnd;
          }
        }

        isScrollTickingRef.current = false;
      });

      isScrollTickingRef.current = true;
    }
  };

  useEffect(() => {
    // resizeListener();
    // scrollListener();

    // document.addEventListener("scroll", scrollListener);
    // window.addEventListener("resize", resizeListener);

    return () => {
      document.removeEventListener("scroll", scrollListener);
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  if (!facility) {
    return null;
  }

  return (
    <section className={styles.hero} data-is="ClubPageHero" ref={heroRef}>
      <span className={styles.content}>
        <ClubPageHeroContent
          club={club}
          clubData={club?.fields?.clubData?.fields}
          clubStatus={facility?.status}
          clubType={
            [
              CLUB_STATUSES.COMING_SOON,
              CLUB_STATUSES.LEAD_INEPT,
              CLUB_STATUSES.PRESALE,
            ].includes(facility?.status)
              ? "special"
              : facility?.clubType
          }
          data={data}
          displayHours={[CLUB_STATUSES.OPEN, CLUB_STATUSES.PRESALE].includes(
            facility?.status
          )}
          facility={facility}
          flag={data?.headlineTag}
          moduleList={moduleList}
        />
      </span>
    </section>
  );
};

ClubPageHero.propTypes = {
  club: PropTypes.object.isRequired,
  data: PropTypes.object,
  facility: PropTypes.object.isRequired,
  moduleList: PropTypes.array,
};

export default ClubPageHero;
